import {
  OfferedAsType,
  OfferingDto,
} from '@wix/bookings-uou-domain/dist/src/offerings/offering.dto';
import { GroupOfferingDto } from '@wix/bookings-uou-domain/dist/src/offerings/group-offering.dto';
import { PriceDomain } from '@wix/bookings-uou-domain/dist/src/domains/price-domain';
import {
  PriceDto,
  PriceDtoFactory,
} from '@wix/bookings-uou-domain/dist/src/domains/price-dto-factory';
import {
  DurationDto,
  DurationDtoFactory,
} from '@wix/bookings-uou-domain/dist/src/domains/duration-dto-factory';
import { DurationDomain } from '@wix/bookings-uou-domain/dist/src/domains/duration-domain';

export const DUMMY_OFFERING_ID = '-1';

export enum OfferingType {
  INDIVIDUAL = 'INDIVIDUAL',
  GROUP = 'GROUP',
  COURSE = 'COURSE',
  TOUR = 'TOUR',
  DUMMY = 'DUMMY',
}

export enum DummyOfferingContext {
  EDITOR_MODE = 'editormode',
  VIEWER_MODE = 'viewermode',
}

const baseDummyOffering = ({ dummyName = '' }) => {
  return {
    dummy: true,
    id: DUMMY_OFFERING_ID,
    categoryId: '-1',
    order: -1,
    type: OfferingType.DUMMY,
    urlName: null,
    offeredAs: [OfferedAsType.ONE_TIME],
    pricingPlanInfo: null,
    info: {
      name: dummyName,
      description: null,
      tagLine: null,
      images: [
        {
          fileName: 'widget-icon.png',
          height: 1000,
          relativeUri: 'e320d0_a08d5b3e1b124f6cb9a5c38182079dc6~mv2.jpg',
          width: 1000,
        },
      ],
    },
    location: {
      type: null,
      locationText: '',
    },
    payment: {
      currency: 'USD',
      price: 20,
      isFree: false,
      priceText: '',
      minCharge: 0,
      paymentType: 'OFFLINE',
    },
    schedulePolicy: {
      displayOnlyNoBookFlow: false,
      maxParticipantsPerOrder: undefined,
      uouHidden: undefined,
      capacity: undefined,
    },
    schedule: {
      startDate: null,
      endDate: null,
      durationInMinutes: 60,
    },
  };
};

export const createDummyOfferingDto = (context: DummyOfferingContext) => {
  switch (context) {
    case DummyOfferingContext.VIEWER_MODE:
      return baseDummyOffering({
        dummyName: 'viewer-dummy-service.title',
      }) as OfferingDto;
    case DummyOfferingContext.EDITOR_MODE:
      return baseDummyOffering({
        dummyName: 'editor-dummy-service.title',
      }) as OfferingDto;
    default:
      return;
  }
};

export class OfferingDomain {
  static createDummyOffering(context: DummyOfferingContext): OfferingDomain {
    return OfferingDomain.fromDto(createDummyOfferingDto(context));
  }

  private constructor(protected offeringDto: OfferingDto) {}

  static fromDto(offeringDto: OfferingDto) {
    return offeringDto ? new OfferingDomain(offeringDto) : null;
  }

  get name() {
    return this.offeringDto.info.name;
  }

  set name(newName: string) {
    this.offeringDto.info.name = newName;
  }

  get type() {
    return this.offeringDto.type;
  }

  get priceText() {
    const priceDto: PriceDto = PriceDtoFactory.fromOfferingDto(
      this.offeringDto,
    );
    return new PriceDomain(priceDto, this.offeringDto.offeredAs).text;
  }

  durationTextByFormat(formatter) {
    const durationDto: DurationDto = DurationDtoFactory.fromOfferingDto(
      this.offeringDto,
    );
    return new DurationDomain(
      this.offeringDto.type,
      durationDto,
    ).getTextByFormat(formatter);
  }

  get pricingPlanInfo() {
    return this.offeringDto.pricingPlanInfo;
  }

  get schedule() {
    return this.offeringDto.schedule;
  }

  daysTextByFormat(formatter) {
    if (this.type === OfferingType.GROUP) {
      const daysOrder =
        formatter('first-day-of-week') === '0'
          ? ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
          : ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
      const classHours = (this.offeringDto as GroupOfferingDto).schedule
        .classHours;
      const offeringDays = Object.keys(classHours);
      const weekDayKeyPrefix: string =
        offeringDays.length === 1 ? 'week-day.' : 'short-week-day.';
      return daysOrder
        .filter(day => classHours[day])
        .map(day => formatter(weekDayKeyPrefix + day))
        .join(', ');
    }
  }

  errorMessageTranslation(formatter) {
    return formatter('tile-service.premium-not-enabled.message');
  }

  get image() {
    const image =
      this.offeringDto.info.images && this.offeringDto.info.images[0];
    return image ? image : null;
  }

  get displayOnlyNoBookFlow() {
    return this.offeringDto.schedulePolicy.displayOnlyNoBookFlow;
  }

  get description() {
    return this.offeringDto.info.description;
  }

  get payment() {
    return this.offeringDto.payment;
  }

  set currency(newCurrency) {
    this.offeringDto.payment.currency = newCurrency;
  }

  get id() {
    return this.offeringDto.id;
  }

  get dummy() {
    return this.offeringDto.dummy;
  }
}
