import * as React from 'react';
import * as s from './offering-duration.scss';
import {
  CatalogOfferingInjectedProps,
  withOfferingContext,
} from '../../../catalog-offering-context';

interface OfferingDurationProps {
  isInfoSeparatorNeeded: boolean;
}

class OfferingDuration extends React.Component<
  CatalogOfferingInjectedProps & OfferingDurationProps
> {
  render() {
    const { offeringViewModel, isInfoSeparatorNeeded } = this.props;
    const durationModel = offeringViewModel.duration;
    return durationModel ? (
      <div
        data-hook="tile-duration"
        tabIndex={0}
        className={`${s.root} ${isInfoSeparatorNeeded && s.withSeparator}`}
      >
        {durationModel}
      </div>
    ) : null;
  }
}

export default withOfferingContext()(OfferingDuration);
