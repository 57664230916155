import * as React from 'react';
import * as s from './offering-description.scss';
import { OfferingIntent } from '../../../../constants';
import { WIDGET_BI_REFERRAL } from '../../../../adapters/reporting/bi-logger/bi.const';
import OfferingAction from '../offering-action/offering-action';

export const MAX_DESCRIPTION = 170;

interface OfferingDescriptionProps {
  onAction(intent: string, biReferral: string): void;
  height?: number;
  shouldShowActionButton: boolean;
  description: string;
  alignmentStyle: string;
}

class OfferingDescription extends React.Component<OfferingDescriptionProps> {
  render() {
    const { alignmentStyle, height, description } = this.props;
    const descriptionModel = this.getDescriptionWithEllipsis(description);
    const containerStyle = height
      ? {
          height: `${height}px`,
        }
      : {};
    return (
      <div className={`${s.root} ${alignmentStyle}`} style={containerStyle}>
        {descriptionModel ? (
          <div
            data-hook="tile-description"
            className={s.descriptionContent}
            onClick={this.onDescriptionClicked}
          >
            <div
              className={`${s.descriptionContentText}`}
              role={'button'}
              tabIndex={0}
            >
              {descriptionModel}
            </div>
          </div>
        ) : null}
        {this.props.shouldShowActionButton ? (
          <div className={s.descriptionAction}>
            <OfferingAction onAction={this.props.onAction} />
          </div>
        ) : null}
      </div>
    );
  }

  private getDescriptionWithEllipsis(description: string) {
    return description && description.length > MAX_DESCRIPTION
      ? `${description.substring(0, MAX_DESCRIPTION)}...`
      : description;
  }

  private readonly onDescriptionClicked = () =>
    this.props.onAction(
      OfferingIntent.SHOW_DETAILS,
      WIDGET_BI_REFERRAL.WIDGET_DESCRIPTION,
    );
}

export default OfferingDescription;
