import * as React from 'react';
import * as s from './offering-title.scss';
import {
  CatalogOfferingInjectedProps,
  withOfferingContext,
} from '../../../catalog-offering-context';
import { OfferingIntent } from '../../../../constants';
import { WIDGET_BI_REFERRAL } from '../../../../adapters/reporting/bi-logger/bi.const';
import { isDummyService } from '../../../SingleWidgetTile/SingleWidgetTile';

interface OfferingTitleProps {
  onAction: Function;
}

class OfferingTitle extends React.Component<
  OfferingTitleProps & CatalogOfferingInjectedProps
> {
  render() {
    const { offeringViewModel, deviceType } = this.props;
    const titleModel = offeringViewModel.title;
    const notDummyStyle = isDummyService(offeringViewModel.type)
      ? ''
      : s.notDummy;

    const classesNames = `${s.root} ${
      offeringViewModel.alignmentStyle
    } ${notDummyStyle} ${s[deviceType] || ''}`;

    return titleModel ? (
      <div
        data-hook="tile-title"
        className={classesNames}
        role={'button'}
        tabIndex={0}
        onClick={this.onAction}
      >
        {titleModel}
      </div>
    ) : null;
  }

  private readonly onAction = () =>
    this.props.onAction(
      OfferingIntent.SHOW_DETAILS,
      WIDGET_BI_REFERRAL.WIDGET_TITLE,
    );
}

export default withOfferingContext()(OfferingTitle);
