import * as React from 'react';
import * as s from './offering-error-message.scss';
import {
  CatalogOfferingContext,
  withOfferingContext,
} from '../../catalog-offering-context';
import { IOfferingViewModel } from '../../../domain/offering-view-model-factory';

interface OfferingTitleProps extends CatalogOfferingContext {
  offeringViewModel: IOfferingViewModel;
  errorMsg: string;
}

interface OfferingTitleState {
  errorMessage: string;
}

class OfferingErrorMessage extends React.Component<
  OfferingTitleProps,
  OfferingTitleState
> {
  constructor(props) {
    super(props);
    this.state = { errorMessage: '' };
  }

  async componentDidMount() {
    const { errorMsg } = this.props;
    this.setState({ errorMessage: errorMsg });
  }

  render() {
    const { errorMessage } = this.state;
    return (
      errorMessage && (
        <div data-hook="tile-error-message" className={s.errorMessageContainer}>
          {errorMessage}
        </div>
      )
    );
  }
}

export default withOfferingContext()(OfferingErrorMessage);
