import * as React from 'react';
import * as s from './offering-days.scss';
import {
  CatalogOfferingInjectedProps,
  withOfferingContext,
} from '../../../catalog-offering-context';

class OfferingDays extends React.Component<CatalogOfferingInjectedProps> {
  render() {
    const { offeringViewModel } = this.props;
    const daysModel = offeringViewModel.days;
    return daysModel ? (
      <div
        tabIndex={0}
        data-hook="tile-days"
        className={`${s.root} ${offeringViewModel.alignmentStyle}`}
      >
        {daysModel}
      </div>
    ) : null;
  }
}

export default withOfferingContext()(OfferingDays);
