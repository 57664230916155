import * as React from 'react';
import * as s from './offering-image.scss';
import {
  withOfferingContext,
  CatalogOfferingInjectedProps,
} from '../../catalog-offering-context';
import OfferingDescription from '../OfferingPrimaryInfo/offering-description/offering-description';
import { ImageDisplayType } from '../../../business-logic/offering-display-options';
import { WixEnvironment } from '../../SingleWidgetTile/SingleWidgetTile';
import { OfferingIntent } from '../../../constants';
import { WIDGET_BI_REFERRAL } from '../../../adapters/reporting/bi-logger/bi.const';
import { DeviceType } from '../../../widget-viewer-main';
import { TileLayout } from '../../../domain/offering-view-model-factory';
import { getImageUrl } from '../../../adapters/media-gallery/media-gallery-adapter';

interface OfferingImageProps {
  dimensions: {
    height: number;
    width: number;
  };
  environment: WixEnvironment;
  onAction(intent: string, biReferral: string): void;
  shouldShowActionButton: boolean;
}

interface OfferingImageState {
  diameter: number;
}

class OfferingImage extends React.Component<
  OfferingImageProps & CatalogOfferingInjectedProps,
  OfferingImageState
> {
  constructor(props) {
    super(props);
    this.state = {
      diameter: this.props.dimensions.height,
    };
  }

  getDescriptionClass = () => {
    const { shouldShowActionButton } = this.props;
    const hasDescription = !!this.props.offeringViewModel.description;
    return (
      `${s.partContainer}` +
      ` ${hasDescription || shouldShowActionButton ? s.hoveredDescription : ''}`
    );
  };

  getImageRequiredDimensions() {
    const isRound =
      this.props.offeringViewModel.image.type === ImageDisplayType.ROUND;
    const { height, width } = this.props.dimensions;
    const isVertical =
      this.props.offeringViewModel.tileLayout === TileLayout.VERTICAL;
    const widthDivider = isVertical ? 1 : 2;
    const heightDivider = isVertical ? 2 : 1;
    if (isRound) {
      const imageRatio = 0.75;
      const diameter = Math.round(
        Math.min(height / heightDivider, width / widthDivider) * imageRatio,
      );
      return {
        height: diameter,
        width: diameter,
      };
    }
    return {
      height: height / heightDivider,
      width: width / widthDivider,
    };
  }

  render() {
    const { offeringViewModel, onAction, dimensions, deviceType } = this.props;
    const isNoImageLayout =
      offeringViewModel.image.type === ImageDisplayType.NONE;
    const imageStyleClass = s[`image-${offeringViewModel.image.type}`];
    const imageSizing = this.getImageRequiredDimensions();
    const imageUrl = getImageUrl(
      offeringViewModel.image.mediaItem,
      imageSizing,
    );
    const isEmptyImage = imageUrl === null;
    const backgroundImage =
      isEmptyImage || isNoImageLayout ? 'none' : `url(${imageUrl})`;

    return (
      <div
        className={`${this.getDescriptionClass()} ${
          s[offeringViewModel.tileLayout]
        } ${s[deviceType] || ''}`}
        role={'img'}
        aria-label={'service image'}
      >
        <div className={s.imageContainer} onClick={this.onImageClicked}>
          <div
            className={`${imageStyleClass} ${isEmptyImage ? s.emptyImage : ''}`}
            data-hook="tile-image"
            style={{
              backgroundImage,
              ...imageSizing,
            }}
          />
        </div>
        {this.isDescriptionAvailable() ? (
          <div
            className={`${s.descriptionContainer} ${
              isNoImageLayout ? s.visible : s.hidden
            }`}
          >
            <OfferingDescription
              description={offeringViewModel.description}
              alignmentStyle={offeringViewModel.alignmentStyle}
              onAction={onAction}
              height={dimensions.height}
              shouldShowActionButton={this.props.shouldShowActionButton}
            />
          </div>
        ) : null}
      </div>
    );
  }

  private readonly onImageClicked = () =>
    this.props.onAction(
      OfferingIntent.SHOW_DETAILS,
      WIDGET_BI_REFERRAL.WIDGET_IMAGE,
    );

  private isDescriptionAvailable() {
    return this.props.deviceType !== DeviceType.MOBILE;
  }
}

export default withOfferingContext()(OfferingImage);
